import iDonateClient from '@idonatedev/idonate-sdk/dist/idonate-client';
import { AxiosError } from 'axios';
import axios from 'services/axiosClient';
import { sdkClientPromise } from 'services/idonateSdk';
import { IGivingFormSchema } from 'components/GivingForm';
import { AllPaymentOptions, Donation } from 'types';
import { DonationAmountRaised } from 'types/givingForm/DonationAmountRaised';

type DonationDataType = IGivingFormSchema & {
  corporateMatchingCompanyId: string;
  emailOptIn?: boolean;
  anonymousGift: boolean;
  gatewayId: string;
  pageId: string;
  sessionId: string;
  type: string;
  organizationId: string;
  campaignId: string;
  referenceCode?: string;
  paymentToken: string;
  captchaToken: string;
  captchaType: string;
  timezone: string;
  embedReferer: string;
  givingFormId: string;
  paymentOption: AllPaymentOptions;
  givingFormVariant?: number;
  givingFormVersion?: number;
  customerMetaOverrides?: {
    [key: string]: any;
  };
  customFields?: {
    [key: string]: any;
  };
  thankYouGift?: {
    description: string;
    fairMarketValue: number;
    giftName: string;
    image: string;
    sku: string;
  };
};

export type OffsiteDonationResult = {
  donor: {
    contact: {
      firstname: string;
      lastname: string;
    };
  };
  transaction: {
    created: string;
    id: string;
    total_value: number; // eslint-disable-line camelcase -- legacy API obeys no camel
    deductible_amount: number; // eslint-disable-line camelcase -- legacy API obeys no camel
  };
};

export function DonationFromOffsiteResult(
  result: OffsiteDonationResult
): Donation {
  // payload returned from offsite checkout is basically a JSON Blob and does
  // not match any well-defined types (yet). This is a fairly gross extraction:

  return {
    // middlename is missing here, but will be reintroduced in SDK or in one of the backends
    fullName: `${result.donor.contact.firstname} ${result.donor.contact.lastname}`,
    transactionCreatedAt: result.transaction.created,
    transactionId: result.transaction.id,
    transactionTotal: result.transaction.total_value,
    deductibleAmount: result.transaction.deductible_amount,

    // offsite does not support recurring.
    billingStartDate: '',
    scheduleId: ''
  };
}

/**
 * This hardcoded object is used to contain values that are required for submission but do not have
 * a reference value provided.  Stub values may also be placed in this object while pending feature
 * completion.
 */
const hardcodedValues = {
  pageId: null, // Leave as null until we figure out how hosted pages work
  type: 'cash' // Any payment is cash, but may support non-cash donations later (e.g. cars)
};

export type SubmitDonationDataType = Omit<
  DonationDataType,
  keyof typeof hardcodedValues
>;

export const submitDonation = async (
  payload: SubmitDonationDataType
): Promise<Donation> => {
  const { data } = await axios.post('/donations', {
    ...hardcodedValues,
    ...payload
  });

  if (data.checkoutUrl) {
    // checkout URL intercepted
    // show URL and wait in the background for it to resolve.
    // possible TODO: do something more elegant than window.open here?
    const offsiteWindow = window.open(
      data.checkoutUrl,
      'Continue Payment',
      'width=600,height=800'
    );

    if (!offsiteWindow) {
      // notify user that the offsite popup was blocked.
      throw new Error(
        'Payment Popup was blocked. Please allow the popup or disable your popup blocker and try again.'
      );
    }

    // wait for external payment promise to return
    const offsiteCheckoutResult = await sdkClientPromise(
      payload.organizationId
    ).then((client: iDonateClient) =>
      client.waitForDonationResult(data.checkoutId)
    );

    // checkout is complete, but returned payload is in an undesirable format
    // for the 'thank-you' screen. Convert it before returning:
    return DonationFromOffsiteResult(offsiteCheckoutResult);
  }

  return data;
};

export const getDonationAmountRaisedData = async (
  organizationId: string,
  givingFormId: string
) => {
  try {
    const response = await axios.get<DonationAmountRaised>(
      `/givingForms/getDonationAmountRaised`,
      {
        params: {
          organizationId,
          givingFormId
        }
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      (error as AxiosError).response?.data.message ||
        'Unknown error trying to load donation amount raised data'
    );
  }
};
