/* eslint-disable @typescript-eslint/no-explicit-any */
import { resolvedEnvironment } from 'services/environment';
import type { IGivingFormSchema } from 'components/GivingForm';

export type SpreedlyResponse = any;

export function unpackSpreedlyResponse(data: any): SpreedlyResponse {
  if (data.errors) {
    const errorMessages = data.errors.map(
      (spreedlyError: { message: string }) => spreedlyError.message
    );
    throw new Error(errorMessages);
  }
  return data;
}

export function extractSpreedlyToken(spreedlyData: SpreedlyResponse): string {
  if (!spreedlyData.transaction || !spreedlyData.transaction.payment_method) {
    throw new Error('Payment Method not tokenized.');
  }
  return spreedlyData.transaction.payment_method.token;
}

export async function tokenizeBankAccountSpreedly(
  data: IGivingFormSchema
): Promise<string> {
  return fetch('https://core.spreedly.com/v1/payment_methods.json?', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      payment_method: {
        bank_account: {
          first_name: data.billing.firstName,
          last_name: data.billing.lastName,
          email: data.billing.email,
          address1: data.billing.address1,
          address2: data.billing.address2,
          city: data.billing.city,
          state: data.billing.state,
          zip: data.billing.postalCode,
          country: data.billing.country,
          // Bank Account
          bank_account_number: data.ach?.accountNumber,
          bank_routing_number: data.ach?.routingNumber
        }
      },
      environment_key: resolvedEnvironment.spreedlyKey,
      retained: false
    })
  })
    .then((resp) => resp.json())
    .then(unpackSpreedlyResponse)
    .then(extractSpreedlyToken);
}
