import {
  ApplePay,
  MerchantSession,
  TokenizeCardConnectApplePayResult
} from '@idonatedev/idonate-sdk';
import { UseFormReturn } from 'react-hook-form';
import { SubmitDonationDataType } from 'services/donationService';
import { getApplePayData, isApplePaySupported } from 'services/idonateSdk';
import { EventHub } from 'components/EventHub/EventHub.types';
import { IGivingFormSchema } from 'components/GivingForm';
import { useDisabledOverlay, useGivingFormData } from 'hooks';

export const useApplePay = (
  methods: UseFormReturn<IGivingFormSchema>,
  eventHub: EventHub | null
) => {
  const { digitalWalletGatewayId, organizationId } = useGivingFormData();
  const { setDisplayOverlay } = useDisabledOverlay();

  const initApplePay = async (
    submissionObject: Partial<SubmitDonationDataType>,
    callback: () => Promise<void>
  ) => {
    if (isApplePaySupported) {
      const handler = await getApplePayData(organizationId || '');

      if (!digitalWalletGatewayId) {
        throw new Error('Digital Wallet Gateway not configured.');
      }
      const { ...fields } = methods.getValues();

      const payload = {
        // : Partial<ApplePayPaymentRequest>
        currencyCode: 'USD', // Add Support for International Currencies
        countryCode: 'US', //  Add Support for International Transactions
        merchantCapabilities: [
          'supports3DS',
          'supportsCredit',
          'supportsDebit'
        ],
        // supported networks: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks
        supportedNetworks: ['amex', 'masterCard', 'visa', 'discover'],
        requiredBillingContactFields: ['postalAddress', 'name'],
        requiredShippingContactFields: ['email', 'phone'],
        total: {
          // fallback to iDonate if org name is not provided
          label: fields.designation[0].title || 'iDonate', // defaulting to 'iDonate' (same as app6)
          amount: fields.giftAmount.toString() || '0', // applepay only takes in strings, defaulting to 0 if null.
          type: 'final'
        }
      };

      const applePay = new ApplePay(
        payload as Partial<ApplePayJS.ApplePayPaymentRequest>
      );
      applePay.begin().then((session: ApplePaySession) => {
        /* eslint-disable no-param-reassign */
        session.oncancel = function (event) {
          setDisplayOverlay(false);
        };

        session.onvalidatemerchant = (merchantValidation) => {
          const validationPayload = {
            apple_pay_url: merchantValidation.validationURL,
            organization_id: organizationId || '',
            origin: eventHub?.props.origin
          };

          applePay
            .createSession(validationPayload, handler.embedBaseUrl)
            .then((merchantSession: MerchantSession) => {
              session.completeMerchantValidation(merchantSession.result);
            });
        };
        session.onpaymentauthorized = (authorizedPayment) => {
          applePay
            .tokenizeWithCardConnect(
              authorizedPayment.payment,
              handler.client.cardConnectBaseUrl
            )
            .then((cardConnectResponse: TokenizeCardConnectApplePayResult) => {
              // SafeGuarding billing contact with shipping contact
              const applePayContact = {
                ...authorizedPayment.payment.billingContact,
                email: authorizedPayment.payment.shippingContact?.emailAddress,
                phone: authorizedPayment.payment.shippingContact?.phoneNumber
              };
              const addressLines = applePayContact?.addressLines;
              let address1 = '';
              let address2 = '';

              if (addressLines && addressLines.length) {
                [address1, address2] = addressLines;
              }
              submissionObject.billing = {
                firstName: applePayContact.givenName || '',
                lastName: applePayContact.familyName || '',
                email: applePayContact.email || '',
                address1: address1 || '',
                address2: address2 || '',
                city: applePayContact?.locality || '',
                state: applePayContact?.administrativeArea || '',
                postalCode: applePayContact.postalCode || '',
                // TODO: ELG - check into special handling of these -- required on type, but optional on schema under certain circumstances
                province: '',
                middleName: '',
                companyName: '',
                country: applePayContact.countryCode || ''
              };

              submissionObject.paymentToken = cardConnectResponse.token;
              /* eslint-enable no-param-reassign */
              try {
                callback();
              } catch (e) {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
              }
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            })
            .catch((reason) => {
              setDisplayOverlay(false);
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              console.error(reason);
            });
        };
      });
    }
  };

  return { initApplePay };
};
