import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import { useWatch } from 'react-hook-form';
import { sdkClientPromise } from 'services/idonateSdk';
import { CCCvv, CCExpDate, CCSubheading } from 'components/CCPaymentInfo';
import Text from 'components/lib/Text';
import { useGivingFormData } from 'hooks';
import { useCreditCardToken } from 'hooks/useCreditCardToken';
import { useCreditCardValidation } from 'hooks/useCreditCardValidation';
import useWindowSize from 'hooks/useWindowSize';
import { acceptedCreditCards } from 'types';

const CardConnectCreditCard = () => {
  const { width } = useWindowSize();
  const { palette } = useTheme();
  const { organizationId } = useGivingFormData();
  const [iframeSrc, setIframeSrc] = useState('');
  const [currentToken, setCurrentToken] = useState<string>('');
  const isMobile = width < 600;
  const { tokenResolver } = useCreditCardToken();
  const { creditCardCc, setCreditCardCc, setCreditCardCvv } =
    useCreditCardValidation();

  // Watch values to use for tokenizing
  const currentCVV = useWatch({ name: 'creditCard.cvv' });
  const currentExpiry = useWatch({ name: 'creditCard.expirationDate' });

  const init = useCallback(async () => {
    if (organizationId) {
      let orientation = 'custom';
      if (isMobile) {
        orientation = 'vertical';
      }

      let css =
        'body {margin: 0;}input {border: 1px solid rgb(218, 220, 232);border-radius: 0.5rem;font-family: "Helvetica Neue", sans-serif;font-size: 1rem;letter-spacing: inherit;line-height: 1;height: 3rem;padding: 0 1rem;background-color: white;}input:hover {background-color: #EEF0FC;}input:hover, input:focus, input:active {border-color: rgba(25, 60, 172, 1);}label {display: none;}input#ccnumfield {width: calc(100.0% - 2.5rem);margin-right: 0.5rem;}input#ccexpiryfieldmonth {width: calc(12.5% - 3rem);margin-right: 0.75rem;}input#ccexpiryfieldyear {width: calc(12.5% - 2.625rem);margin-right: 0.75rem;}input#cccvvfield {width: calc(25.0% - 3.125rem);}';
      if (isMobile) {
        css =
          'body {margin: 0;}input {border: 1px solid rgb(218, 220, 232);border-radius: 0.5rem;font-family: "Helvetica Neue", sans-serif;font-size: 1rem;letter-spacing: inherit;line-height: 1;height: 3rem;padding: 0 1rem;background-color: white;}input:hover {background-color: #EEF0FC;}input:hover, input:focus, input:active {border-color: rgba(25, 60, 172, 1);}label {font-family: "Helvetica Neue",sans-serif;font-weight: 700;font-size: 1rem;line-height: 1;color: #656677;margin-right: 0.5rem;margin-top: 0.5rem;line-height: 48px;}label#cccardlabel {display: none;}label#ccexpirylabel {width: 100%;display: inline-block;}label#cccvvlabel {width: 100%;}label[for="ccexpiryfieldyear"] {display: none;}input#ccnumfield {width: calc(100.0% - 2.25rem);}input#ccexpiryfieldmonth {width: 2rem;}input#ccexpiryfieldmonth+br {display:none;}input#ccexpiryfieldyear {width: 3rem; margin-left: 1rem;}input#cccvvfield {width: 3rem;}br:first-of-type {display: none;}';
      }
      const client = await sdkClientPromise(organizationId);
      const iframeSrcUrl = client.generateCardConnectTokenizerUrl();
      const [baseUrl] = iframeSrcUrl.split('?');

      // DOC: https://developer.cardpointe.com/hosted-iframe-tokenizer#iFrame-controls
      const newQueryParams = {
        enhancedresponse: 'true',
        usemonthnames: 'false',
        useexpiry: 'false',
        useexpiryfield: 'false',
        usecvv: 'false',
        placeholderyear: 'YYYY',
        invalidinputevent: 'true',
        cardnumbernumericonly: 'true',
        placeholder: 'Enter Card Number',
        placeholdermonth: 'MM',
        placeholdercvv: 'CVV',
        formatinput: 'true',
        expirymonthtitle: 'Month',
        orientation,
        unique: 'true',
        expirylabel: 'Expiration *',
        cvvlabel: 'CVV *',
        css,
        tokenizewheninactive: 'true',
        inactivityto: 500
      };

      setIframeSrc(`${baseUrl}?${qs.stringify(newQueryParams)}`);
    }
  }, [organizationId, isMobile]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event.origin.includes('cardconnect.com')) {
        const cardData = JSON.parse(event.data);
        if (cardData.errorCode !== '0') {
          setCreditCardCc('error');
        } else {
          setCreditCardCc('valid');
          // Don't set the token in state here. This will prevent us
          // from sending the token before we've attached the cvv/exp to it.
          setCurrentToken(cardData.message);
        }
      }
    };

    window.addEventListener('message', handleMessageEvent);

    return () => window.removeEventListener('message', handleMessageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCreditCardCc, setCreditCardCvv, tokenResolver]);

  useEffect(() => {
    const tokenizeCC = async (orgId: string) => {
      try {
        const client = await sdkClientPromise(orgId);
        const { data } = await axios.post(
          `${client.cardConnectBaseUrl}/cardsecure/api/v1/ccn/tokenize`,
          {
            account: currentToken,
            cvv: currentCVV,
            expiry: currentExpiry
          }
        );

        if (data.errorcode === 0) {
          tokenResolver(data.token);
        }
      } catch (err) {
        // tokenization failed at the execution level.
        // not sure if we need to reset this?
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (
      currentToken &&
      currentExpiry &&
      currentExpiry.length === 4 &&
      currentCVV &&
      currentCVV.length >= 3 &&
      organizationId
    ) {
      // Only update the token once we've got a token from the iframe, an expiration date that's
      // 4 digits, and a cvv that's at least 3 digits. From the frontend the CC iframe
      // doesn't tell us what brand of card we're dealing with so we can't determine if the
      // cvv should be 3 or 4 digits.
      tokenizeCC(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, currentToken, currentCVV, currentExpiry]);

  return iframeSrc ? (
    <>
      <Grid className="GF__GridInputWrapper" item xs={12} sm={6}>
        <Box display="flex" flexDirection="column">
          <CCSubheading currentCardType={acceptedCreditCards} />
          <iframe
            title="Card Connect"
            id="tokenframe"
            name="tokenframe"
            src={iframeSrc}
            scrolling="no"
            frameBorder="0"
            width="100%"
            height="52px"
          />
          {creditCardCc === 'error' && (
            <Text color={palette.red.main} variant="h6">
              Invalid credit card number
            </Text>
          )}
        </Box>
      </Grid>
      <CCExpDate />
      <CCCvv />
    </>
  ) : null;
};

export default CardConnectCreditCard;
