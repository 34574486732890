import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
  styled
} from '@mui/material';
import { ICONS } from 'components/lib/Icon';
import { SelectMenuItem } from '.';
import './Select.scss';

export interface SelectOption {
  label?: string;
  value: string;
  icon?: FC;
}

export interface SelectProps
  extends Omit<MuiSelectProps, 'label' | 'defaultValue' | 'disabled'> {
  label?: string;
  options: SelectOption[];
  defaultValue?: string;
  disabled?: boolean;
  helperText?: ReactNode;
}

const styledChevronIcon = (disabled: boolean) =>
  styled(ICONS.CHEVRON_DOWN)(({ theme }) => ({
    '&': { top: 'unset' },
    stroke: disabled
      ? theme.palette.lightGray.main
      : theme.palette.darkGray.main
  }));

const Select = ({
  options,
  label,
  defaultValue,
  disabled = false,
  helperText,
  ...props
}: SelectProps & { name: string }): JSX.Element => {
  const [value, setValue] = React.useState<string>(defaultValue || '');
  const { error } = props;
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    props?.onChange?.(event, child);
    setValue(event.target.value);

    setTimeout(() => {
      // Every instance of a Select component is passing in a className
      // Cast to avoid `string | undefined` error.
      const selectElement = document.getElementsByClassName(
        props.className as string
      )[0] as HTMLElement;
      if (selectElement === null) {
        console.error('Unable to find current select input...');
      }

      // Select the dropdown element inside the MUI component
      // Focus it if we've found one.
      const buttonElement = selectElement.querySelector(
        '[role="button"]'
      ) as HTMLElement;
      buttonElement?.focus();
    });
  };

  const selectId = `${label}-select-id`;

  const selectProps = {
    ...props,
    sx: props.sx,
    defaultValue,
    labelId: `${label}-select-label-id`,
    id: selectId,
    label,
    value,
    disabled,
    onChange: handleChange
  };

  const onFocus = useCallback(() => {
    const selectedOption =
      menuRef.current?.querySelector(
        `[role="option"][data-value="${value}"]`
      ) ?? menuRef.current?.querySelector('[role="option"]');
    (selectedOption as HTMLElement)?.focus();
  }, [value]);

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  return (
    <FormControl fullWidth error={error}>
      {!!label && (
        <InputLabel
          disabled={disabled}
          id={`${label}-select-label-id`}
          htmlFor={selectId}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect
        MenuProps={{
          className: 'custom-select-menu',
          keepMounted: true,
          disablePortal: true,
          TransitionProps: {
            onEntered: onFocus
          },
          ref: menuRef
        }}
        {...selectProps}
        IconComponent={styledChevronIcon(disabled)}
      >
        {options.map((x) => SelectMenuItem(x, props.name))}
      </MuiSelect>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
